.status-box {
  width: 400px;
  height: 200px;
  display: flex;
  margin: $default-spacing auto;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 20px;

  &.status-success {
    background-color: $hubl-green;
  }

  &.status-danger {
    background-color: $hubl-light-red;
  }

  &__icon {
    height: 100px;
    width: 100px;
    background-color: transparent;
    box-sizing: border-box;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    height: 30px;
    font-size: 28px;
    color: $white;
  }
  &.success {
    background-color: $hubl-green;
  }
}

.seven-days-table {
  tr {
    > th,
    td {
      text-align: left !important;
    }
  }

  .day-circle {
    $size: 20px;
    width: $size;
    height: $size;
    border-radius: $size / 2;
    background-color: $hubl-very-light-bg;
    box-sizing: border-box;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-circle {
      $inner-size: $size / 2.5;
      width: $inner-size;
      height: $inner-size;
      border-radius: $inner-size / 2;
    }

    &.failed {
      .inner-circle {
        background-color: $hubl-light-red;
      }
    }

    &.resolved {
      border-color: $hubl-light-red;
      .inner-circle {
        background-color: $hubl-green;
      }
    }

    &.passed {
      .inner-circle {
        background-color: $hubl-green;
      }
    }
  }
}

.issue-breakdown-table {
  tbody {
    tr {
      td:first-child {
        > i {
          font-size: 22px;
          color: $hubl-green;

          &.danger {
            color: $hubl-light-red;
          }
        }
        padding: 13px;
      }
    }
  }
}
