.hubl-icon {
  svg {
    width: 30px !important;
    height: 30px !important;
  }
  &.success {
    path {
      fill: $hubl-green !important;
    }
  }

  &.failed {
    path {
      fill: $hubl-light-red !important;
    }
  }
}
