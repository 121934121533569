.checklist-table {
  td,
  th {
    font-size: 12px !important;
    text-align: left !important;
  }

  tr {
    > th {
      color: white;
      background-color: $primary;
    }
  }
}

.checklists {
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__footer {
    text-align: right;
    padding: 20px;
  }
}
