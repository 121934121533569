/* ===============
 * MODAL STYLES
 * =============== */

.overlay {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  &:not(.visible) {
    display: none;
  }

  .modal {
    position: static;
    background-color: white;
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;
    cursor: default;

    .modal-header {
      width: 100%;
      border-bottom: 0;
      display: block;
      width: 100%;

      > p,
      h2,
      h3,
      h4,
      h5 {
        color: $secondary;
        text-align: center;
        font-size: $hubl-big-font-size;
      }
    }

    .modal-body {
      width: 100%;
      padding: $default-spacing;
    }

    .modal-footer {
      width: 100%;
      padding: $default-spacing;
    }
  }
}
