.box {
  width: 100%;
  box-shadow: 1px 1px 5px 0 rgba(90, 97, 117, 0.25);

  &__header {
    background-color: $primary;
    color: $white;
    height: 30px;
    width: 100%;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding-top: 6px;
  }

  &__body {
    background-color: $white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: height 400ms linear;
  }
}
