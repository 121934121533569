/* ===============
 * LAYOUT SETTINGS
 * =============== */

html,
body {
  margin: 0;
  padding: 0;
  background-color: $hubl-light-bg;
  height: auto;
}

#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;

  > aside {
    overflow: hidden;
    width: $sidebarWidth;
    height: 100%;
    transition: width 400ms ease-in;
    position: fixed;

    &.hidden {
      width: 0;
      transition: width 400ms ease-out;
    }

    &:not(.hidden) ~ main {
      padding-left: $sidebarWidth;
      transition: padding 400ms ease-in;
    }
  }

  > main {
    flex: 1;
    transition: padding 400ms ease-out;

    > header {
      width: 100%;
      height: 50px;
    }
  }
}

/* ===============
 * NAVIGATION
 * =============== */

aside.hubl-sidebar-wrapper {
  background-color: $primary;
  padding-top: $content-padding-side;
  box-sizing: border-box;
  z-index: 10;

  & * {
    z-index: 11;
  }

  .separator-container {
    width: $sidebarWidth;
    .separator {
      box-sizing: border-box;
      border-radius: 5px;
      height: 4px;
      background-color: $white;
      width: 150px;
      margin: $default-spacing * 2 auto;
    }
  }

  nav {
    width: $sidebarWidth;
    .sidebar-logo {
      margin-top: 24px;
      text-align: left;
      padding-left: 30px;
      margin-bottom: 42px;

      img {
        width: 140px;
      }

      .company-name {
        font-size: $medium-text-size;
        color: $white;
      }
    }

    ul {
      padding: 0;
      margin: $content-padding-top - $content-padding-side 0 0 0;

      > li {
        height: 56px;
        list-style: none;
        cursor: pointer;
        transition: background-color 600ms ease-out;
        box-sizing: border-box;
        border-left: 5px solid transparent;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-left: 30px;
          position: absolute;
          top: 13px;
        }

        > a {
          width: $sidebarWidth;
          height: 55px;
          display: block;
          margin: 0;
          text-decoration: none;
          color: $white;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: left;
          padding-top: 18px;
          padding-bottom: 18px;
          padding-left: 80px;
        }

        * {
          user-select: none;
        }
        span {
          color: white;
          font-size: $hubl-label-font-size;
          float: left;
          line-height: $hubl-label-font-size;
          margin-top: 20px;
        }
        i {
          color: white;
          font-size: 30px;
          line-height: 30px;
          float: left;
          margin: 13px 10px;
        }

        &.active {
          background-color: $secondary;
          box-sizing: border-box;
          border-color: $white;
        }

        &:hover {
          background-color: $secondary;
          transition: background-color, border-color 200ms ease-in;
          border-color: $white;
        }

        &:active:not(.active) {
          background-color: darken($secondary, 10%);
          box-sizing: border-box;
        }
      }
    }
  }
}

/* ===============
 * HEADER
 * =============== */

header {
  background-color: transparent;
  color: $hubl-gray;
  position: relative;
  box-sizing: border-box;

  .hamburger {
    @include button-states($primary);
    cursor: pointer;
    height: 30px;
    width: 40px;
    border-radius: 3px;
    margin-left: $content-padding-side;
    text-align: center;
    i {
      user-select: none;
      color: $white;
      font-size: 20px;
      line-height: 20px;
      margin-top: 5px;
    }
  }

  h1 {
    color: $primary;
    > span,
    > div,
    > i,
    > button,
    > a {
      color: $primary;
    }
  }

  .user-menu {
    position: absolute;
    right: 20px;
    top: 12px;
    * {
      color: $primary;
    }

    &__logout {
      &::after {
        content: '';
        position: absolute;
        top: -7px;
        right: 6px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;

        border-bottom: 7px solid $white;
      }
      &:hover {
        &::after {
          border-color: darken($white, 10%) !important;
        }
      }

      &:active {
        &::after {
          background-color: darken($white, 20%) !important;
        }
      }
      @extend %hubl-box-shadow;
      display: none;
      position: absolute;
      background-color: $white;
      top: $icon-size + 10px;
      right: 0;
      width: auto;
      z-index: 100;

      &:hover {
        display: block;
      }

      > a {
        @include button-states($white);
        border-radius: 0;
        width: 100%;
        height: 100%;
        display: block;
        padding: 10px 30px;
        text-decoration: none;
      }
    }

    button {
      border: 0;
      outline: 0 !important;
      background-color: transparent;

      &.user-button.focus {
        & + .user-menu__logout {
          display: block;
        }
      }
    }

    i.fa {
      font-size: $icon-size;
      line-height: $icon-size;
      height: $icon-size;
    }

    .user-name {
      font-size: $hubl-label-font-size;
      margin-bottom: 10px;
      position: relative;
      right: 75px;
      top: 15px;
      line-height: $hubl-label-font-size;
      height: $hubl-label-font-size;
    }

    .logout-button {
      &:before {
        content: '';
        height: $hubl-label-font-size;
        width: 1px;
        background-color: $primary;
        display: block;
        position: absolute;
        right: 40px;
        top: 0;
      }
      position: absolute;
      right: 0;
      top: 13px;
      width: 30px;
      height: 40px;

      > i.fa {
        font-size: $hubl-label-font-size;
        line-height: $hubl-label-font-size;
        height: $hubl-label-font-size;
        width: $hubl-label-font-size;
        color: $primary;
      }
    }
  }
}

/* ===============
 * MAIN
 * =============== */

#main-container {
  main {
    padding: $content-padding-top $content-padding-side;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #content {
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
      height: auto;
    }
  }
}

.fluid {
  width: 100%;
}

.container,
.container-fluid {
  padding: 0 !important;
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    > div:first-child {
      padding-left: 0 !important;
    }
    > div:last-child {
      padding-right: 0 !important;
    }
  }
}

.nopadding {
  padding-right: 0;
  padding-left: 0;
}
