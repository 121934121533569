$class-mg-top: mg-top !default;
$class-mg-bottom: mg-bottom !default;
$class-mg-left: mg-left !default;
$class-mg-right: mg-right !default;
$class-mg-lr: mg-lr !default;
$class-mg-tb: mg-tb !default;
$class-mg: mg !default;

$class-pd-top: pd-top !default;
$class-pd-bottom: pd-bottom !default;
$class-pd-left: pd-left !default;
$class-pd-right: pd-right !default;
$class-pd-lr: pd-lr !default;
$class-pd-tb: pd-tb !default;
$class-pd: pd !default;

$breakpoints: (
  "xs": 320px,
  "sm": 576px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1200px,
);

@each $bp, $size in $breakpoints {
  @for $i from 1 through 12 {
    .#{$class-mg-top}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-top: 10px * $i;
      }
    }

    .#{$class-mg-bottom}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-bottom: 10px * $i;
      }
    }

    .#{$class-mg-left}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-left: 10px * $i;
      }
    }

    .#{$class-mg-right}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-right: 10px * $i;
      }
    }

    .#{$class-mg-lr}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-left: 10px * $i;
        margin-right: 10px * $i;
      }
    }

    .#{$class-mg-tb}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin-top: 10px * $i;
        margin-bottom: 10px * $i;
      }
    }

    .#{$class-mg}-#{$bp}-#{$i*10} {
      @media (max-width: $size) {
        margin: 10px * $i;
      }
    }
  }
}

@for $i from 1 through 12 {
  .#{$class-mg-top}-#{$i*10} {
    margin-top: 10px * $i;
  }

  .#{$class-mg-bottom}-#{$i*10} {
    margin-bottom: 10px * $i;
  }

  .#{$class-mg-left}-#{$i*10} {
    margin-left: 10px * $i;
  }

  .#{$class-mg-right}-#{$i*10} {
    margin-right: 10px * $i;
  }

  .#{$class-mg-lr}-#{$i*10} {
    margin-left: 10px * $i;
    margin-right: 10px * $i;
  }

  .#{$class-mg-tb}-#{$i*10} {
    margin-top: 10px * $i;
    margin-bottom: 10px * $i;
  }

  .#{$class-mg}-#{$i*10} {
    margin: 10px * $i;
  }
}

@for $i from 1 through 12 {
  .#{$class-pd-top}-#{$i*10} {
    padding-top: 10px * $i;
  }

  .#{$class-pd-bottom}-#{$i*10} {
    padding-bottom: 10px * $i;
  }

  .#{$class-pd-left}-#{$i*10} {
    padding-left: 10px * $i;
  }

  .#{$class-pd-right}-#{$i*10} {
    padding-right: 10px * $i;
  }

  .#{$class-pd-lr}-#{$i*10} {
    padding-left: 10px * $i;
    padding-right: 10px * $i;
  }

  .#{$class-pd-tb}-#{$i*10} {
    padding-top: 10px * $i;
    padding-bottom: 10px * $i;
  }

  .#{$class-pd}-#{$i*10} {
    padding: 10px * $i;
  }
}
