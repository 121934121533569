/* ===============
 * GLOBAL SETTINGS
 * ===============
 *
 * CSS DIRECTORY
 *	0. =COLORS AND SIZES
 *	1. =MIXINS
 *	2. =FONTS
 *	3. =HEADERS
 *	4. =FORM
 *	5. =BUTTONS
 *	6. =TAGS
 *  7  =SMALL COMPONENTS (divider etc.)
 */

/* ===0. COLORS AND SIZES=== */

// Colors
$hubl-gray: #5a6175;
$hubl-dark-gray: #484d5e;
$hubl-green: #00deb6;
$hubl-button: rgba(140, 184, 197, 1);
$hubl-button-disabled: rgba(140, 184, 197, 0.5);
$hubl-box-shadow: 1px 1px 5px 0 rgba(90, 97, 117, 0.5);
$hubl-form-bg: rgba(0, 0, 0, 0.12);
$hubl-input-bg: rgba(255, 255, 255, 0.9);
$hubl-medium-gray: $hubl-gray;
$hubl-input-border: $hubl-medium-gray;
$hubl-comment-bg: $hubl-medium-gray;
$hubl-label-color: $hubl-medium-gray;
$hubl-default-font-color: $hubl-gray;
$hubl-light-font-color: #cccccc;
$hubl-light-bg: #f3f3f3;
$hubl-very-light-bg: #f1f1f1;
$hubl-light-border: #f6f6f6;
$hubl-medium-bg: #e7e7e7;

$hubl-light-red: #ff8f97;

$marine-blue: #034569;
$deep-sea-blue: #05507a;
$hubl-orange: #f97959;
$light-orange: #ffab00;
$info-blue: #8baddb;
$hubl-red: #ee2f04;
$hubl-icon-red: #d92e07;
$hubl-sea-green: #36b37e;
$hubl-icon-green: #34a475;
$turquoise: #07a8c8;
$white: white;

$primary: $hubl-gray;
$secondary: $hubl-dark-gray;
$accent: $hubl-green;
$secondary-orange: $light-orange;
// SIZES
$hubl-radius: 4px;
$hubl-label-font-size: 16px;
$hubl-table-font-size: 10px;
$hubl-default-font-size: 12px;
$hubl-big-font-size: 24px;
$content-padding-top: 20px;
$content-padding-side: 18px;
$content-padding: 24px;
$default-spacing: 16px;

$icon-size: 20px;

$sidebarWidth: 250px;

$medium-text-size: 15px;

/* ===1. MIXINS=== */

@mixin browserReset() {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

@mixin grayscale_element($value) {
  -webkit-filter: #{'grayscale(#{$value})'};
  -moz-filter: #{'grayscale(#{$value})'};
  filter: #{'grayscale(#{$value})'};
}

@mixin button-states($color) {
  background-color: $color;
  border-color: $color;
  border-radius: 10px;

  &:focus {
    background-color: $color;
    border-color: $color;
  }

  &:hover {
    background-color: darken($color, 10%) !important;
    border-color: darken($color, 10%) !important;
  }

  &:active {
    background-color: darken($color, 20%) !important;
    border-color: darken($color, 20%) !important;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background-color: grayscale_element($color);
  }
}

@mixin button-transparent-states($color) {
  color: $color;
  border-color: $color;

  &:hover {
    color: darken($color, 5%) !important;
    border-color: darken($color, 5%) !important;
  }

  &:active {
    color: darken($color, 10%) !important;
    border-color: darken($color, 10%) !important;
  }
}

@mixin collapsed-side-margin($margin) {
  &:first-child {
    margin-left: $margin;
    margin-right: $margin / 2;
  }

  &:last-child {
    margin-right: $margin;
    margin-left: $margin / 2;
  }

  &:not(:first-child):not(:last-child) {
    margin-right: $margin / 2;
    margin-left: $margin / 2;
  }
}

@mixin tag($color) {
  background-color: $color;
  border-color: $color;
  color: white;
  border-radius: $hubl-radius;
  font-size: $hubl-label-font-size;
  outline: none;
  box-shadow: none !important;
}

/* ===2. FONTS=== */

:root,
body,
body * {
  font-family: 'Rubik', sans-serif;
  font-size: $hubl-default-font-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $hubl-default-font-color;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===3. HEADERS=== */

h1 {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $hubl-default-font-color;

  &.main-title {
    > span {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
}

h2 {
  font-size: $hubl-label-font-size;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $hubl-default-font-color;
}

/* ===4. FORM=== */

%hubl-box-shadow {
  box-shadow: $hubl-box-shadow;
}

%input-style {
  @extend %hubl-box-shadow;
  border-radius: $hubl-radius;
  border: 1px solid transparent;
  background-color: $hubl-input-bg;
  color: $hubl-default-font-color;
  -webkit-appearance: none;

  &:hover,
  &:focus {
    background-color: darken($hubl-input-bg, 3%);
  }

  &:active {
    background-color: darken($hubl-input-bg, 6%);
  }

  &:invalid {
    color: $hubl-red;
    border-color: $hubl-red !important;
  }
}

input:not([type='checkbox']):not([type='radio']),
input:not([type='checkbox']):not([type='radio']):focus,
textarea {
  @include browserReset();
  outline: 0;

  &:active {
    @include browserReset();
    outline: 0;
  }
}

input:not([type='checkbox']):not([type='radio']),
input:not([type='checkbox']):not([type='radio']):focus {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']) {
  @extend %input-style;
  font-size: 14px;
  padding: 10px;
  max-height: 34px;
  height: 34px;
}

textarea {
  @extend %input-style;
}

label {
  color: $hubl-label-color;
  font-size: $hubl-label-font-size;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

input[type='checkbox'] {
  outline: 0;
  position: relative;
  top: -2px;
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    border: 2px solid white;
    background-color: white;
    color: white;
    display: block;
    position: absolute;
    right: -3px;
    top: 1px;
    z-index: 2;
  }

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: $hubl-input-border;
    color: $hubl-input-border;
    padding: 0;
    display: block;
    position: absolute;
    right: -4px;
    top: 0;
  }
  &:checked {
    &:before {
      background-color: $accent;
      color: $accent;
      border: 2px solid white;
    }
  }

  &:hover:not(:checked) {
    &:before {
      background-color: lighten($accent, 25%);
      color: $accent;
      border: 2px solid white;
    }
  }

  & + label {
    position: relative;
    left: 5px;
  }
}

%invalid {
  border-color: $hubl-red !important;
  color: $hubl-red !important;
  background-color: lighten($hubl-red, 50%) !important;
}

// From boostrap

.select-group {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-transform: uppercase;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > .hubl-select {
    &:not(:first-child) {
      margin-left: 38px;

      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

.hubl-select {
  .hubl-select-single-value {
    position: absolute;
    height: 30px;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 4px;
    padding-left: 30px;
  }
  span[class$='-indicatorSeparator'] {
    display: none;
  }

  div[class$='-placeholder'] {
    text-transform: uppercase;
    font-family: Rubik, sans-serif;
    font-size: $medium-text-size;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 6px;
    position: absolute;
    height: 30px;
    width: 200px;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
  }

  input {
    border: 0;
    height: 100%;
    width: 100%;
    box-shadow: none !important;
  }

  * {
    font-family: 'Rubik' !important;
    border: 0 !important;
    outline: none !important;
  }
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-check-label {
  font-size: $hubl-default-font-size;
  white-space: nowrap;
  margin-left: 4px;
  user-select: none;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $hubl-label-color;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: $hubl-input-border;
  box-shadow: none;
  outline: 0;
}

.form-text {
  color: $hubl-label-color;
}

// DateTimePicker

.form-group.datepicker {
  position: relative;
  small {
    color: $hubl-label-color;
    margin-top: 5px;
  }

  .datepicker-addon.invalid {
    @extend %invalid;

    * {
      @extend %invalid;
    }
  }

  .datepicker-addon {
    position: absolute;
    right: -10px;
    bottom: 0;
    max-height: 6px;
    background-color: $hubl-input-bg;
    display: block;
    height: 34px;
    min-height: 34px;
    width: 34px;
    min-width: 34px;
    border: 1px solid $hubl-input-border;
    border-left: 0 !important;
    box-sizing: border-box;

    > i.fa {
      color: $hubl-input-border;
      line-height: 16px;
      font-size: 14px;
      max-height: 28px;
      position: absolute;
      bottom: 3px;
      border-left: 1px solid $hubl-input-border;
      padding: 6px 9px;
      display: block;
    }
  }
}

.hubl-datepicker {
  width: 100%;
}

.rdt {
  > input {
    font-size: $hubl-default-font-size !important;
    color: $hubl-label-color !important;

    &.invalid {
      @extend %invalid;
    }
  }
}

/* ===5. BUTTONS=== */
%button-common {
  padding: 4px 26px;
  height: auto;
  color: white;
  border-radius: $hubl-radius;
  font-size: $hubl-table-font-size;
  line-height: 12px;
  outline: none;
  box-shadow: none !important;
  text-decoration: none;

  * {
    color: white;
    text-decoration: none;
  }

  i {
    width: $hubl-table-font-size;
    height: $hubl-table-font-size;
  }
}

.btn-xl {
  padding: $default-spacing / 2 $default-spacing * 2;
  font-size: $hubl-default-font-size;
  line-height: $hubl-default-font-size;
  outline: none;
  box-shadow: none !important;
  text-decoration: none;
  border-radius: $default-spacing * 2 !important;
  height: auto;
  width: auto;
  text-align: center;
}

.btn-primary {
  @extend %button-common;
  @include button-states($hubl-button);
}

.btn-default {
  @extend %button-common;
  @include button-states($hubl-button);
}

.btn-info {
  @extend %button-common;
  @include button-states($info-blue);
}

.btn-secondary {
  @extend %button-common;
  @include button-states(#cccccc);
}

.btn-danger {
  @extend %button-common;
  @include button-states($hubl-red);
}

.btn-warning {
  @extend %button-common;
  @include button-states($accent);

  &:disabled {
    background-color: #cccccc;
  }
}

.btn-transparent {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  border-color: transparent;
}

/* ===6. TAGS=== */

.tag {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 12px;
  line-height: $hubl-label-font-size;
  border-radius: $hubl-radius;
  width: 100%;
}

.tag-primary {
  @include tag($primary);
}

.tag-info {
  @include tag($info-blue);
}

.tag-danger {
  @include tag($hubl-red);
}

.tag-warning {
  @include tag($accent);
}

.tag-active {
  @include tag($secondary-orange);
}

.tag-default {
  @include tag(#cccccc);
}

/* ===7. SMALL COMPONENTS== */

.divider {
  width: 100%;
  height: 0;
  border-bottom: 1px solid $hubl-light-bg;
  margin: $content-padding 0;
}

.alert-danger {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

i.fa-trash {
  color: $hubl-label-color;
}
