body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}

.ql-editor strong {
  font-weight: 700;
}

.ql-editor em {
  font-style: italic;
}

.ql-editor u {
  text-decoration: underline;
}

/* Félkövér és dőlt stílus kombinációja */
.ql-editor strong em,
.ql-editor em strong {
  font-weight: 700;
  font-style: italic;
}

/* Félkövér és aláhúzott stílus kombinációja */
.ql-editor strong u,
.ql-editor u strong {
  font-weight: 700;
  text-decoration: underline;
}

/* Dőlt és aláhúzott stílus kombinációja */
.ql-editor em u,
.ql-editor u em {
  font-style: italic;
  text-decoration: underline;
}

/* Félkövér, dőlt és aláhúzott stílus kombinációja */
.ql-editor strong em u,
.ql-editor strong u em,
.ql-editor em strong u,
.ql-editor em u strong,
.ql-editor u strong em,
.ql-editor u em strong {
  font-weight: 700;
  font-style: italic;
  text-decoration: underline;
}
