%cell-style {
  border-bottom: 1px solid $hubl-light-border;
  border-left: 0;
  border-right: 0;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 0;
  font-size: $hubl-label-font-size;
  font-family: Rubik;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

@mixin radius-collapse($radius) {
  &:first-child {
    .page-link {
      border-radius: $radius 0 0 $radius !important;
    }
  }

  &:last-child {
    .page-link {
      border-radius: 0 $radius $radius 0 !important;
    }
  }

  &:not(:first-child):not(:last-child) {
    .page-link {
      border-radius: 0 !important;
    }
  }
}

table.table {
  margin: 0;
  thead {
    tr {
      th {
        @extend %cell-style;
      }
    }
  }
  tbody {
    tr {
      td {
        @extend %cell-style;

        .btn {
          margin: auto 5px;
          display: inline-block;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

nav {
  .pagination {
    li.page-item {
      @include radius-collapse($hubl-radius * 2);
      .page-link {
        outline: 0;
        box-shadow: none;
        background-color: $hubl-input-bg !important;
        border-color: $hubl-input-border !important;
        color: $hubl-label-color !important;
        width: 40px;
        height: 40px;
        padding: 12px;
        font-size: $hubl-label-font-size;
        padding: $default-spacing / 2;
        text-align: center;
      }
      &.active {
        .page-link {
          color: black !important;
          border: 1px solid $hubl-input-border !important;
          background-color: white !important;
        }
      }
    }
  }
}
