#login {
  background-size: cover !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .form {
    width: 100%;
    padding: $default-spacing;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 250px;

    label {
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
    }
  }

  .login-box {
    width: 360px;
    background-color: white;
  }

  .login-button {
    width: 100%;
  }
}
